import emailjs from "@emailjs/browser";

export const submitLead = async (values) => {
  try {
    const templateParams = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      email: values.email,
      zipcode: values.zipcode,
    };
    const res = await emailjs.send(
      process.env.REACT_APP_MAILER_SERVICE,
      process.env.REACT_APP_MAILER_TEMPLATE,
      templateParams,
      process.env.REACT_APP_MAILER_PUBLIC_KEY
    );
    return res;
  } catch (e) {
    console.log("ERROR ", e);
  }
};
