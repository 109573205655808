const SERVICES = [
  {
    id: "cooling",
    description:
      "Need AC work you can hang your hat on? At Jon Wayne, over 80% of our techs are NATE-certified, so you know you'll get a job done well. Plus, you can always expect convenient, 'red-carpet' service",
    includes: [
      "Central AC Services",
      "Heat Pump Services",
      "Ductless AC Services",
      "...and more",
    ],
  },
  {
    id: "heating",
    description:
      "Want a heating company with good, old-fashioned values? Choose Jon Wayne and get honest, convenient service—from the first call to the final handshake.",
    includes: [
      "Furnace Services",
      "Heat Pump Services",
      "Wall Heater Services",
      "...and more",
    ],
  },
  {
    id: "plumbing",
    description:
      "Need a plumber you can trust? With over 200 trucks in the area, we're always right around the corner. Every Jon Wayne truck is fully stocked so that our plumbers are ready to handle any job.",
    includes: [
      "Drain & Pipe Services",
      "Water Heater Services",
      "Water Filtration Services",
      "...and more",
    ],
  },
  {
    id: "electrical",
    description:
      "Our licensed electricians offer high-quality electrical repairs and installations. Our approach is simple: provide 'red-carpet' customer service and convenient electrical services.",
    includes: [
      "Lighting & Ceiling Fan Services",
      "Generator Services",
      "Surge Protector Services",
      "...and more",
    ],
  },
];

export default SERVICES;
